import RtButton from "components/button/RtButton"
import ContentContainer from "components/layout/ContentContainer"
import SectionContainer from "components/layout/SectionContainer"

import PageNav from "components/nav/PageNav"
import RtActionButton from "components/button/RtActionButton"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import RtInput from "components/input/RtInput"
import RTDatePicker from "components/datepicker/RTDatepicker"
import RtLabel from "components/input/RtLabel"
import { Plus, Trash } from "react-feather"
import { TicketFormDataType } from "types/index.types"
import RTError from "components/input/RTError"
import RTFormHelperText from "components/input/RTFormHelperText"
import RtBanner from "components/banner"
import { formatDateToDisplay } from "utils/date-formatter.util"

interface AddTicketGroupProps {
  handleCreateEvent: () => void
  ticketGroups: TicketFormDataType[]
  handleChange: (value: { [key: string]: any }, idx: number) => void
  isEventPublishing: boolean
  handleAddTicketGroup: () => void
  handleRemoveTicketGroup: (idx: number) => void
  eventDates?: {
    start_date: Date | null
    end_date: Date | null
  }
}

function AddTicketGroup({
  handleCreateEvent,
  handleChange,
  ticketGroups,
  isEventPublishing,
  handleAddTicketGroup,
  handleRemoveTicketGroup,
  eventDates,
}: AddTicketGroupProps) {
  return (
    <div>
      <SectionContainer overrideClass="lg:w-1/2 lg:mx-auto">
        <PageNav>Add ticket group</PageNav>

        {ticketGroups.map((ticketGroup, idx) => (
          <div className="mb-5" key={`add-ticket-group-${idx}`}>
            {idx > 0 && (
              <div className="flex justify-end mb-2">
                <RtActionButton
                  handleClick={() => handleRemoveTicketGroup(idx)}
                  name="Remove ticket"
                  icon={<Trash size={20} />}
                  overrideClass="border-none"
                />
              </div>
            )}

            <ContentContainer>
              <div className="flex flex-col gap-5">
                <RtInput
                  label="Ticket Name"
                  labelOverrideClass="mb-0"
                  inputName="name"
                  inputPlaceholder="Regular"
                  inputValue={ticketGroup?.name || ""}
                  onInputChange={(value) => handleChange(value, idx)}
                  isRequired
                  inputOverrideClass="w-full"
                  errorFieldName={`ticket_groups.${idx}.name`}
                />

                <div className="flex gap-3">
                  <div className="flex-1">
                    <RtInput
                      label="Price"
                      labelOverrideClass="mb-0"
                      inputName="price"
                      inputPlaceholder="500"
                      inputValue={ticketGroup?.price || ""}
                      onInputChange={(value) => handleChange(value, idx)}
                      isRequired
                      errorFieldName={`ticket_groups.${idx}.price`}
                    />
                    {ticketGroup?.price && (
                      <RTFormHelperText
                        helperText={`Your earnings after 7% commission: KES ${(
                          Number(ticketGroup.price) * 0.93
                        ).toLocaleString()} (Commission: KES ${(
                          Number(ticketGroup.price) * 0.07
                        ).toLocaleString()})`}
                      />
                    )}
                  </div>

                  <RtInput
                    label="Seats"
                    labelOverrideClass="mb-0"
                    inputName="seats"
                    inputPlaceholder="500"
                    inputValue={ticketGroup?.seats || ""}
                    onInputChange={(value) => handleChange(value, idx)}
                    isRequired
                    errorFieldName={`ticket_groups.${idx}.seats`}
                  />
                </div>

                {eventDates?.end_date && (
                  <RtBanner
                    overrideWrapperStyles="w-max"
                    title="Ticket Sales Deadline"
                    description={
                      <div>
                        Tickets should be sold before the event ends on{" "}
                        {formatDateToDisplay(eventDates?.end_date!)}.
                      </div>
                    }
                    bannerType="info"
                  />
                )}

                <div>
                  <RtLabel label="Start date & Time" isRequired />
                  <RTFormHelperText helperText="Select the date and time when the tickets will be on sale." />

                  <RTDatePicker
                    placeholderText="Select start date & Time"
                    showTimeSelect
                    selectedDate={ticketGroup?.start_date}
                    handleChange={(date: Date | null) =>
                      handleChange({ start_date: date }, idx)
                    }
                  />
                  <RTError inputName={`ticket_groups.${idx}.start_date`} />
                </div>

                <div>
                  <RtLabel label="End date & Time" isRequired />
                  <RTFormHelperText helperText="Select the date and time when the ticket sales will be closed." />

                  <RTDatePicker
                    placeholderText="Select end date & Time"
                    showTimeSelect
                    selectedDate={ticketGroup?.end_date}
                    handleChange={(date: Date | null) => {
                      handleChange({ end_date: date }, idx)
                    }}
                  />
                  <RTError inputName={`ticket_groups.${idx}.end_date`} />
                </div>
              </div>
            </ContentContainer>
          </div>
        ))}

        <RtActionButton
          handleClick={handleAddTicketGroup}
          name="Add ticket"
          icon={<Plus size={20} />}
          overrideClass="border-none"
        />

        <div className="mt-5">
          <RtButton
            overrideClass=" w-full"
            onClick={handleCreateEvent}
            disabled={isEventPublishing}
          >
            CREATE EVENT
          </RtButton>

          <ActionLoadingModal
            isOpen={isEventPublishing}
            loadingText="Your event is being created"
          />
        </div>
      </SectionContainer>
    </div>
  )
}

export default AddTicketGroup
