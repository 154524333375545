import { useNavigate } from "react-router-dom";

function FAQPage() {
  const navigate = useNavigate();

  const faqs = [
    {
      question: "What is Ticket Vipi?",
      answer:
        "Ticket Vipi is an online ticketing platform for events happening in Kenya and beyond.",
    },
    {
      question: "What is the commission rate for event hosts?",
      answer:
        "A 7% commission fee is deducted from each ticket sale. For example, if you set a ticket price of KES 1,000, your earnings will be KES 930 (93%) and the commission will be KES 70 (7%).",
    },
    {
      question: "How do I create an account?",
      answer: (
        <>
          Click{" "}
          <span
            className="text-[#3030F1] underline cursor-pointer font-arial text-[14px]"
            onClick={() => navigate("/sign-up")}
          >
            Sign Up
          </span>{" "}
          on the homepage and follow the instructions to register.
        </>
      ),
    },
    {
      question: "How do I purchase tickets?",
      answer:
        "Browse events, select tickets, and complete the payment process.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "Visa, MasterCard, MPESA, and other supported payment methods.",
    },
    {
      question: "Can I cancel or exchange tickets?",
      answer:
        "Tickets are generally non-refundable unless specified by the event organizer.",
    },
    {
      question: "How will I receive my tickets?",
      answer: "Via email or accessible in your account.",
    },
    {
      question: "How can I contact support?",
      answer: (
        <>
          Email:{" "}
          <a
            href="mailto:support@ticketvipi.com"
            className="text-[#3030F1] underline font-arial text-[14px]"
          >
            support@ticketvipi.com
          </a>
          , Phone:{" "}
          <a 
            href="tel:+254112130196" 
            className="text-[#3030F1] underline font-arial text-[14px]"
          >
            +254 112 130 196
          </a>
          .
        </>
      ),
    },
  ];

  return (
    <div className="max-w-6xl mx-auto py-10 px-4 font-arial">
      <h1 className="text-[26px] font-bold mb-8 text-black">Frequently Asked Questions</h1>
      
      <div className="space-y-6">
        {faqs.map((faq, index) => (
          <div key={index} className="p-4 border-b border-gray-300">
            <h2 className="font-semibold text-[19px] text-black">{faq.question}</h2>
            <p className="mt-2 text-[14px] text-[#595959]">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQPage;
